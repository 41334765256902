import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { TextInput, ResponsiveFlex, ProgressButtons } from "cerulean";
import { entities, modules, useFeature } from "byzantine";
import { useLocalization } from "@fluent/react";
import AccountSelector from "../../AccountSelector";
import * as formAdapters from "../../../src/adapters";
import HeaderWithSteps from "../../HeaderWithSteps";
import LimitsDialog from "../LimitsDialog";
import { useSudoContext } from "../../SudoContext";
import { LimitsForDisplayType } from "../ach/ACHPaymentRoutes";
import { TRANSFERS_ROUTE, TRANSFER_DETAILS_ROUTE } from "./TransferRoutes";

const TOTAL_STEPS = 3;

function AccountSelectSection({ limits }: { limits: LimitsForDisplayType }) {
  const institution = entities.institution.useInstitution();
  const { featureEnabled } = useFeature();
  const form = modules.transfers.TransferForm.useForm();
  const selectedSourceAccount = modules.transfers.useSelectedSourceAccount();
  const selectedDestinationAccount =
    modules.transfers.useSelectedDestinationAccount();

  const sourceAccounts = entities.accounts.useValidTransferSources(
    selectedDestinationAccount?.id,
    featureEnabled("internal_transfers"),
  );

  const destinationAccounts = entities.accounts.useValidTransferDestinations(
    !!institution?.ach_allows_push,
    selectedSourceAccount?.id,
    featureEnabled("internal_transfers"),
    featureEnabled("enable_loans_in_transfer_flow"),
  );

  // TODO: Figure out if needed.
  // const showAddExternalAccountLinkInSourceDropdown = useMemo(() => {
  //   // if (!showExternalAccountsInSourceDropdown) {
  //   //   return false;
  //   // }
  //   if (!selectedDestinationAccount) {
  //     return true;
  //   }

  //   return selectedDestinationAccount.isValidAchTransferDestination() || false;
  // }, [accounts, selectedDestinationAccount]);

  return (
    <>
      <ResponsiveFlex gapSize="m">
        <h4 className="fontSize--m nds-sans">Transfer details</h4>
        <div className="transfer-amount-container">
          <TextInput
            label="Amount"
            {...formAdapters.amountInput(form, "amount")}
          />
        </div>
        <LimitsDialog limitsForDisplay={limits} />
        <h4 className="fontSize--m nds-sans padding--top--xs">
          Select accounts
        </h4>
        <div className="transfer-accounts-container padding--bottom--s">
          <AccountSelector
            accounts={sourceAccounts}
            label="From"
            field="fromAccount"
            isDestination={false}
            {...formAdapters.select(form, "fromAccount")}
          />
        </div>
        <div className="transfer-accounts-container">
          <AccountSelector
            accounts={destinationAccounts}
            label="To"
            field="toAccount"
            isDestination={true}
            {...formAdapters.select(form, "toAccount")}
          />
        </div>
      </ResponsiveFlex>
    </>
  );
}

export default function AccountSelectScreen({
  limits,
}: {
  limits: LimitsForDisplayType;
}) {
  const navigate = useNavigate();
  const { l10n } = useLocalization();
  const { useSetInitialSteps } = useSudoContext();

  const onSubmitSuccessful = useCallback(() => {
    navigate(`/${TRANSFERS_ROUTE}/${TRANSFER_DETAILS_ROUTE}`);
  }, [navigate]);

  const handleSubmit = entities.transfers.useHandleAccountsPageSubmit({
    onSubmitSuccessful,
  });

  useSetInitialSteps(TOTAL_STEPS);

  return (
    <ResponsiveFlex gapSize="xl">
      <HeaderWithSteps
        headerText="Make a transfer"
        step={1}
        totalSteps={TOTAL_STEPS}
      />

      <AccountSelectSection limits={limits} />

      <ProgressButtons
        backLabel={l10n.getString("button-cancel")}
        nextLabel={l10n.getString("button-next")}
        onBack={() => {
          navigate("/transfers");
        }}
        onNext={handleSubmit}
      />
    </ResponsiveFlex>
  );
}
