import * as Yup from "yup";

import { createForm } from "../../forms";
import { cents } from "../../../utils";

export type TransferFundsForm = {
  fromAccount: API.AccountId;
  toAccount: API.AccountId;
  amount: Cents;
  agreement: boolean;
  instant: boolean | undefined;
  memo: string | undefined;
  transferUuid: string | undefined;
  recurringRule: string | undefined;
};

export const validationSchema = Yup.object().shape({
  fromAccount: Yup.string().required("Required"),
  toAccount: Yup.string().required("Required"),
  amount: Yup.number()
    .integer("Please enter a number.")
    .positive("Please enter a positive amount.")
    .required("Required"),
  agreement: Yup.boolean()
    .equals([true], "Must agree to transfer terms.")
    .required("Required."),
  instant: Yup.boolean().optional(),
  memo: Yup.string()
    .length(140, "Cannot be more than 140 characters.")
    .optional(),
  transferUuid: Yup.string().optional(),
  recurringRule: Yup.string().optional(),
});

export const initialValues: TransferFundsForm = {
  fromAccount: "" as API.AccountId,
  toAccount: "" as API.AccountId,
  amount: cents(0),
  memo: "",
  agreement: false,
  instant: false,
  transferUuid: undefined,
  recurringRule: undefined,
};

export const transformImmediateTransferFundsFormFieldsToApiFields = ({
  fromAccount: from_account_id,
  toAccount: to_account_id,
  amount,
  agreement,
  instant,
  transferUuid: transfer_uuid,
  recurringRule: recurring_rule,
}: TransferFundsForm) => {
  return {
    amount,
    from_account_id,
    to_account_id,
    agreement,
    instant,
    transfer_uuid,
    recurring_rule,
  } as API.TransferScheduledRequest;
};

export const TransferForm = createForm({
  initialValues,
  validationSchema,
});
