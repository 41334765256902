import { useAccount } from "../../entities";

import { TransferForm } from "./TransferFunds.form";

export const useSelectedSourceAccount = () => {
  const {
    values: { fromAccount },
  } = TransferForm.useForm();
  return useAccount(fromAccount);
};

export const useSelectedDestinationAccount = () => {
  const {
    values: { toAccount },
  } = TransferForm.useForm();
  return useAccount(toAccount);
};
